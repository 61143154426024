/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://cv7u1mz78e.execute-api.ap-northeast-1.amazonaws.com/devseven",
            "region": "ap-northeast-1"
        },
        {
            "name": "OutlookCalendarWebhook",
            "endpoint": "https://dz0962l6r0.execute-api.ap-northeast-1.amazonaws.com/devseven",
            "region": "ap-northeast-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://bqtxawfchzbbdmeetauk2ph2n4.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-r2udya6by5fabgbbf43l5s66yy",
    "aws_cognito_identity_pool_id": "ap-northeast-1:d099e40b-3bb6-4d01-97b2-b0d340d2210e",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_xMqgQ81Rg",
    "aws_user_pools_web_client_id": "7n0lfqtdk7807jrfsdfs4qtmc",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "ORGANIZATION"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "coteam2-dev101918-devfour03452-devseven",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
